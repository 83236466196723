@import './_reset';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  white-space: pre-line;
  word-break: keep-all;
  font-family: Roboto, Noto Sans KR, Noto Sans Thai, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
